<template>
  <!-- <div> -->
  <div class="popup_wrap" style="width: 800px;">
    <!-- popup_wrap : style="width:600px height:490px" 참고용, 개발시 제거 -->
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->

      <h1 v-if="repReqNo.length === 0" class="page_title">
        {{ $t('msg.CSBK100.510') }}
      </h1>
      <!-- <h1 v-else class="page_title">OOG 선적 문의 상세</h1> -->

      <div v-if="repReqNo.length === 0" class="content_box">
        <!-- content_box -->
        <form id="frm">
          <table class="tbl_row">
            <colgroup>
              <col width="130">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('art.CMATK160') }}</th>
                <td>
                  <!-- {{ params.cntrSzCd }} -->
                  <select id="cntrSzCd" v-model="params.cntrSzCd" @change="changeSzTyp('N')" style="width: 100px">
                    <option value="" disabled selected>{{ $t("msg.CSBL200.075") }}</option>
                    <option
                      v-for="option in sizeList"
                      :key="option.cd"
                      :value="option.cd"
                    >
                      {{ option.cdNm }}
                    </option>
                  </select>
                </td>
                <th> {{ $t('art.CMATK161') }}</th>
                <td>
                  <!-- {{ params.cntrTypCd }} -->
                  <select id="cntrTypCd" v-model="params.cntrTypCd" @change="changeSzTyp('N')" style="width: 100px">
                    <option value="" disabled selected>{{ $t("msg.CSBL200.075") }}</option>
                    <option
                      v-for="option in typeList"
                      :key="option.cd"
                      :value="option.cd"
                    >
                      {{ option.cd }}
                    </option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="tbl_col mt10">
            <colgroup>
              <col width="25%">
              <col width="25%">
              <col width="25%">
              <col width="25%">
              <!-- <col width="16%">
              <col width="20%"> -->
            </colgroup>
            <tbody>
              <tr>
                <th>Length (cm)</th>
                <td>
                  <e-input-number
                    :id="'orgLength'"
                    v-model="oogInfo.orgLength"
                    :is-comma="true"
                    :point="2"
                    :digit-max="5"
                    @input="checkSize('orgLength')"
                  >
                  </e-input-number>
                  <div
                    v-if="overLength !== '' && overLength > 0"
                    class="txt_size"
                  >
                    Over Size: (F) +{{ setComma(overLength) }}㎝<br>(B) +{{ setComma(overLength) }}㎝
                  </div>
                  <div
                    v-else-if="oogInfo.orgLength !== '' && overLength == 0"
                    class="txt_size"
                  >
                    In-gauge
                  </div>
                </td>
                <th>Width (cm)</th>
                <td>
                  <e-input-number
                    :id="'orgWidth'"
                    v-model="oogInfo.orgWidth"
                    :is-comma="true"
                    :point="2"
                    :digit-max="5"
                    @input="checkSize('orgWidth')"
                  >
                  </e-input-number>
                  <div
                    v-if="overWidth !== '' && overWidth > 0"
                    class="txt_size"
                  >
                    Over Size: (L) +{{ setComma(overWidth) }}㎝<br>(R) +{{ setComma(overWidth) }}㎝
                  </div>
                  <div
                    v-else-if="oogInfo.orgWidth !== '' && overWidth == 0"
                    class="txt_size"
                  >
                    In-gauge
                  </div>
                </td>
              </tr>

              <tr>
                <th>Height (cm)</th>
                <td>
                  <e-input-number
                    :id="'orgHeight'"
                    v-model="oogInfo.orgHeight"
                    :is-comma="true"
                    :point="2"
                    :digit-max="5"
                    @input="checkSize('orgHeight')"
                  >
                  </e-input-number>
                  <div
                    v-if="overHeight !== '' && overHeight > 0"
                    class="txt_size"
                  >
                    Over Size: +{{ setComma(overHeight) }}㎝
                  </div>
                  <div
                    v-else-if="oogInfo.orgHeight !== '' && overHeight == 0"
                    class="txt_size"
                  >
                    In-gauge
                  </div>
                </td>
                <th>Weight (kg)</th>
                <td>
                  <e-input-number
                    :id="'frgtWt'"
                    v-model="oogInfo.frgtWt"
                    :is-comma="true"
                    :point="3"
                    :digit-max="7"
                  >
                  </e-input-number>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
        <div class="txt_size_bk">
          * cm 기준입니다.
        </div>
      </div>
      <!-- content_box // -->

      <OogDetailPop
        v-if="repReqNo.length > 0"
        :detail="detail"
        style="width:700"
      />
      <div v-if="repReqNo.length === 0" class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="cargoCheck()">조회</a>
      </div>
    </div>
    <!-- popup_cont -->
  </div>
  <!-- popup_wrap // -->
  <!-- </div> -->
</template>

<script>
import { rootComputed } from '@/store/helpers'
import Oogs from '@/api/rest/trans/oogs'
import OogDetailPop from '@/pages/trans/popup/OogDetailPop'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EAutoCompleteCommodity from '@/components/common/EAutoCompleteCommodity'
import EInputNumber from '@/components/common/EInputNumber'

export default {
    name: 'OogDgInquiryPop',
    components: {
        OogDetailPop,
        EAutoCompletePlace,
        EAutoCompleteCommodity,
        EInputNumber
    },
    data: function () {
        return {
            oogForms: '',
            cmdtList: [],
            cmdtDscList: [],
            polCtrList: [],
            polPlcList: [],
            polTrmlList: [],
            podCtrList: [],
            podPlcList: [],
            podTrmlList: [],
            sizeList: [],
            typeList: [],
            typeList2040: [],
            typeList45: [],
            params: {
                cntrSzCd: '',
                cntrTypCd: '',
                // polCtrCd: '',
                // podCtrCd: '',
                polPortCd: '',
                podPortCd: '',
                polTrmlCd: '',
                podTrmlCd: '',
                len: '',
                widt: '',
                hght: '',
                frgtWt: '',
                cmdtCd: '',
                cmdtNm: '',
                cmdtCdDsc: ''
            },
            repReqNo: [],
            detail: [{ }],
            chkHidden: false,
            pol: {
              ctrCd: '',
              plcCd: ''
            },
            pod: {
              ctrCd: '',
              plcCd: ''
            },
            startPlcCd: '',
            startPlcName: '',
            destPlcCd: '',
            startCtrCd: '',
            destCtrCd: '',
            destPlcName: '',
            startPolName: '',
            destPodName: '',

            commodityNm: '',
            commodityCd: '',
            ctrCd: '',
            overSizeInfo: {
              FR20: { length: 560, width: 243, height: 221 },
              FR40: { length: 1177, width: 243, height: 195 },
              OT20: { length: 588, width: 234, height: 231 },
              OT40: { length: 1204, width: 235, height: 234 },
              HC45: { length: 1350, width: 235, height: 269 }
            },
            overLength: '',
            overWidth: '',
            overHeight: '',
            simulation: '',
            deadSpace: '',
            oogInfo: {
              orgLength: '',
              orgWidth: '',
              orgHeight: '',
              frgtWt: ''
            },
            oogInputChk: false
        }
    },
    computed: {
      ...rootComputed
    },
    created () {
      this.initCommCodes()

      if (this.auth !== undefined) {
        this.ctrCd = this.memberDetail.userCtrCd
      }
    },
    methods: {
      setComma (num) {
        return this.$ekmtcCommon.changeNumberFormat(num, { isComma: true })
      },
      changeCommodity (vo) {
        this.params.cmdtCd = vo.cd
        this.params.cmdtNm = vo.cdNm
      },
      async changePol (vo) {
        this.polTrmlList = []
        this.params.polTrmlCd = ''

        this.pol = vo
        this.startPolName = vo.plcEnm
        this.startCtrCd = vo.ctrCd
        this.startPlcCd = vo.plcCd
        this.startPlcName = vo.plcEnm
        this.polTrmlList = await Oogs.getOogTrml(vo.plcCd)

        if (this.polTrmlList.total !== '0') {
          this.polTrmlList = this.polTrmlList.trmlList
        } else {
          this.params.polTrmlCd = ' '
        }

        this.params.polPortCd = vo.plcCd

        // this.checkPol()
        // this.changePolTrml()
      },
      async changePod (vo) {
        this.podTrmlList = []
        this.params.podTrmlCd = ''

        this.pod = vo
        this.destPodName = vo.plcEnm
        this.destCtrCd = vo.ctrCd
        this.destPlcCd = vo.plcCd
        this.destPlcName = vo.plcEnm
        this.podTrmlList = await Oogs.getOogTrml(vo.plcCd)

        if (this.podTrmlList.total !== '0') {
          this.podTrmlList = this.podTrmlList.trmlList
        } else {
          this.params.podTrmlCd = ' '
        }

        this.params.podPortCd = vo.plcCd
      },
      checkCommodity () {
        let frm = document.querySelector('#frm')
        const cSize = frm.querySelector('#commodity')
        const val = cSize.value

        let isOk = true
        let msg = ''

        if (this.params.cmdtNm === '') {
          msg = this.$t('msg.SPEC020P020.015') // Please Select Commidity.
          isOk = false
        }

        if (!isOk) {
          this.LocalShowErrorTooltip(cSize, msg, 'input')
        } else {
          this.localHideErrorTooltip(cSize, 'input')
        }
        return isOk
      },
      checkPol () {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#polPol')
        const $elem = $(elem)

        let isOk = true
        let msg = ''

        if (this.params.polPortCd === '') {
          msg = this.$t('msg.SPEC020P020.013') // Please Input POL Code.
          isOk = false
        }

        if (!isOk) {
          this.LocalShowErrorTooltip(elem, msg, 'input')
        } else {
          this.localHideErrorTooltip(elem, 'input')
        }
        return isOk
      },
      checkPod () {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#podPod')
        const val = elem.value

        let isOk = true
        let msg = ''

        if (this.params.podPortCd === '') {
          msg = this.$t('msg.SPEC020P020.014') // Please Input POD Code.
          isOk = false
        }

        if (!isOk) {
          this.LocalShowErrorTooltip(elem, msg, 'input')
        } else {
          this.localHideErrorTooltip(elem, 'input')
        }
        return isOk
      },
      async initCommCodes () {
          this.oogForms = await Oogs.getOogForm()
          this.cmdtList = this.oogForms.data.cmdtList
          this.polCtrList = this.oogForms.data.ctrList
          this.podCtrList = this.oogForms.data.ctrList

          this.oogForms.data.typeList.forEach((item, idx) => {
            if (item.cd === 'FR' || item.cd === 'OT' || item.cd === 'HC') {
              this.typeList.push(item)
            }

            if (item.cd === 'FR' || item.cd === 'OT') {
              this.typeList2040.push(item)
            }

            if (item.cd === 'HC') {
              this.typeList45.push(item)
            }
          })

          this.oogForms.data.sizeList.forEach((item, idx) => {
            if (item.cd !== ' ') {
              this.sizeList.push(item)
            }
          })
      },
      changeSzTyp (gubun) {
        const frm = document.querySelector('#frm')
        const elemSz = frm.querySelector('#cntrSzCd')
        const valSz = elemSz.value

        const elemTyp = frm.querySelector('#cntrTypCd')
        const valTyp = elemTyp.value

        let elem = frm.querySelector('#cntrSzCd')
        let isOk = true
        let msg = ''
        let msgSz = ''
        let msgTyp = ''

        // 화면에서 size별 type 제한
        if (this.params.cntrSzCd === '20' || this.params.cntrSzCd === '40') {
          this.typeList = this.typeList2040
        }

        if (this.params.cntrSzCd === '45') {
          this.typeList = this.typeList45
        }

        if (gubun === 'Y') {
          if (this.$ekmtcCommon.isEmpty(valSz)) {
            msgSz = this.$t('msg.SPEC020030.001')
            isOk = false
            this.LocalShowErrorTooltip(elemSz, msgSz)
          }

          if (this.$ekmtcCommon.isEmpty(valTyp)) {
            msgTyp = this.$t('msg.SPEC020P020.001') // Please Input Container Type
            isOk = false
            this.LocalShowErrorTooltip(elemTyp, msgTyp)
          }
        } else {
            if (this.$ekmtcCommon.isNotEmpty(valSz)) {
              this.$ekmtcCommon.hideErrorTooltip(elemSz)
            }

            if (this.$ekmtcCommon.isNotEmpty(valTyp)) {
              this.$ekmtcCommon.hideErrorTooltip(elemTyp)
            }

            if (this.$ekmtcCommon.isNotEmpty(valTyp) && this.$ekmtcCommon.isNotEmpty(valSz)) {
              this.oogInputChk = false
            }

            if (this.$ekmtcCommon.isNotEmpty(valTyp) && this.$ekmtcCommon.isNotEmpty(valSz)) {
              if (valSz === '45') {
                  elem = elemSz
                  if (valTyp !== 'HC') {
                    msg = this.$t('msg.SPEC020030.002')
                    isOk = false
                  } else if (valTyp === 'HC') {
                    isOk = true
                    this.$ekmtcCommon.hideErrorTooltip(elemSz)
                  }
              } else { // valSz = 20 or 40
                if (valTyp === 'HC') {
                    elem = elemTyp
                    if (valSz !== '45') {
                      msg = this.$t('msg.SPEC020030.003') // HC는  45'컨테이너만 선택가능합니다.,WMH
                      isOk = false
                    } else if (valSz === '45') { // valSz === 'HC'
                      isOk = true
                    }
                } else { // valTyp === OT or FR
                  isOk = true
                  this.$ekmtcCommon.hideErrorTooltip(elemTyp)
                }
              }
            }

          if (!isOk) {
            this.LocalShowErrorTooltip(elem, msg)
          } else {
            this.$ekmtcCommon.hideErrorTooltip(elem)
          }
        }
        this.checkSize('orgLength', 'S')
        this.checkSize('orgHeight', 'S')
        this.checkSize('orgWidth', 'S')
        return isOk
      },
      changePolTrml () {
        this.checkPol()

        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#polTrmlCd')
        const val = elem.value
        let isOk = true
        let msg = ''

        if (this.params.polTrmlCd === '') {
            msg = this.$t('msg.SPEC020P020.002')// Please Input POL TRML Code.
            isOk = false
        }
        if (this.params.polTrmlCd === '' && this.params.polPortCd !== '' && this.polTrmlList.length === 0) {
            isOk = true
        }

        if (!isOk) {
          this.$ekmtcCommon.showErrorTooltip(elem, msg)
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        return isOk
      },
      changePodTrml () {
        this.checkPod()

        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#podTrmlCd')
        const val = elem.value
        let isOk = true
        let msg = ''

        if (this.params.podTrmlCd === '') {
            msg = this.$t('msg.SPEC020P020.003') // Please Input POD TRML Code.
            isOk = false
        }
        if (this.params.podTrmlCd === '' && this.params.podPortCd !== '' && this.podTrmlList.length === 0) {
            isOk = true
        }

        if (!isOk) {
          this.LocalShowErrorTooltip(elem, msg)
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        return isOk
      },
      changeLen () {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#orgLength')
        const val = elem.value
        let isOk = true
        let msg = ''

        if (val === '') {
          msg = this.$t('msg.SPEC020P020.004') // Please Input Length (cm).
          isOk = false
        }

        if (!isOk) {
          this.LocalShowErrorTooltip(elem, msg)
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        return isOk
      },
      changeWidt () {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#orgWidth')
        const val = elem.value
        let isOk = true
        let msg = ''

        if (val === '') {
          msg = this.$t('msg.SPEC020P020.005') // Please Input Width (cm).
          isOk = false
        }

        if (!isOk) {
          this.LocalShowErrorTooltip(elem, msg)
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        return isOk
      },
      changeHght () {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#orgHeight')
        const val = elem.value
        let isOk = true
        let msg = ''

        if (val === '') {
          msg = this.$t('msg.SPEC020P020.006') // Please Input Height (cm).
          isOk = false
        }

        if (!isOk) {
          this.LocalShowErrorTooltip(elem, msg)
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        return isOk
      },
      changeFrgtWt () {
        const frm = document.querySelector('#frm')
        const elem = frm.querySelector('#frgtWt')
        const val = elem.value
        let isOk = true
        let msg = ''

        if (val === '') {
          //msg = this.$t('msg.SPEC020P020.007') // Please Input Weight (kg).
          isOk = false
        }

        if (!isOk) {
          this.LocalShowErrorTooltip(elem, msg)
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elem)
        }
        return isOk
      },
      checkValidationOOG (prefix) {
        let isOk = true
        const frm = document.querySelector('#frm')
        const id = prefix
        const selector = frm.querySelector('#' + id)

        const cntrSzCd = this.params.cntrSzCd
        const cntrTypCd = this.params.cntrTypCd
        const cgoTypCd = ''
        // const emptyFlag = this.emptyFlag
        const oogInfo = this.oogInfo

        this.$ekmtcCommon.hideErrorTooltip(selector)

        if (prefix === 'orgLength') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }

          if (cntrTypCd === 'FR') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 560) {
                //this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.199')) // 'Length(cm)은 560까지 입력 가능합니다.'
                isOk = false
              }
            } else if (Number(oogInfo.orgLength) > 1177) {
             // this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.200')) // 'Length(cm)은 1,177까지 입력 가능합니다.'
              isOk = false
            }
          }

          if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 588) {
              //  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.201')) // 'Length(cm)은 588까지 입력 가능합니다.'
                isOk = false
              }
            } else {
              if (Number(oogInfo.orgLength) > 1204) {
              //  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.202')) // 'Length(cm)은 1,204까지 입력 가능합니다.'
                isOk = false
              }
            }
          }
          if (cntrTypCd === 'HC') {
            if (Number(oogInfo.orgLength) > 1350) {
              //  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.201_1')) // 'Length(cm)은 560까지 입력 가능합니다.'
                isOk = false
            }
          }
        }
        if (prefix === 'orgWidth') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }

          if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgWidth) > 234) {
               // this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.203'))
                isOk = false
              }
            } else {
              if (Number(oogInfo.orgWidth) > 235) {
               // this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.204'))
                isOk = false
              }
            }
          }
          if (cntrTypCd === 'FR') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgWidth) > 243) {
               // this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.203_1'))
                isOk = false
              }
            } else {
              if (Number(oogInfo.orgWidth) > 243) {
              //  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.204_1'))
                isOk = false
              }
            }
          }
          if (cntrTypCd === 'HC') {
            if (Number(oogInfo.orgWidth) > 235) {
            //    this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.204')) // 'Length(cm)은 560까지 입력 가능합니다.'
                isOk = false
            }
          }
        }

        if (prefix === 'orgHeight' && this.$ekmtcCommon.isNotEmpty(selector.value)) {
          let flagCnt = 0
          if (cgoTypCd === '02') { // OOG
            if (cntrTypCd === 'FR') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgLength) > 560) {
                  flagCnt++
                }
                if (Number(oogInfo.orgWidth) > 243) {
                  flagCnt++
                }
                if (Number(oogInfo.orgHeight) > 221) {
                  flagCnt++
                }
              } else {
                if (Number(oogInfo.orgLength) > 1177) {
                  flagCnt++
                }
                if (Number(oogInfo.orgWidth) > 243) {
                  flagCnt++
                }
                if (Number(oogInfo.orgHeight) > 195) {
                  flagCnt++
                }
              }
            } else if (cntrTypCd === 'OT') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgHeight) > 231) {
                  flagCnt++
                }
              } else {
                if (Number(oogInfo.orgHeight) > 234) {
                  flagCnt++
                }
              }
            }
            if (flagCnt === 0) {
              this.$ekmtcCommon.showErrorTooltip(selector, 'Please check cargo size again! (Out of-gauge cargo)')
              isOk = false
            }
          } else if (cgoTypCd === '03') {
            const length = frm.querySelector('#orgLength')
            const width = frm.querySelector('#orgWidth')
            const height = frm.querySelector('#orgHeight')
            if (cntrTypCd === 'FR') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgLength) > 560) {
                  this.$ekmtcCommon.showErrorTooltip(length, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
                if (Number(oogInfo.orgWidth) > 243) {
                  this.$ekmtcCommon.showErrorTooltip(width, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
                if (Number(oogInfo.orgHeight) > 221) {
                  this.$ekmtcCommon.showErrorTooltip(height, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
              } else {
                if (Number(oogInfo.orgLength) > 1177) {
                  this.$ekmtcCommon.showErrorTooltip(length, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
                if (Number(oogInfo.orgWidth) > 243) {
                  this.$ekmtcCommon.showErrorTooltip(width, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
                if (Number(oogInfo.orgHeight) > 195) {
                  this.$ekmtcCommon.showErrorTooltip(height, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
              }
            } else if (cntrTypCd === 'OT') {
              if (cntrSzCd === '20') {
                if (Number(oogInfo.orgLength) > 588) {
                  this.$ekmtcCommon.showErrorTooltip(length, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
                if (Number(oogInfo.orgWidth) > 234) {
                  this.$ekmtcCommon.showErrorTooltip(width, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
                if (Number(oogInfo.orgHeight) > 231) {
                  this.$ekmtcCommon.showErrorTooltip(height, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
              } else {
                if (Number(oogInfo.orgLength) > 1204) {
                  this.$ekmtcCommon.showErrorTooltip(length, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
                if (Number(oogInfo.orgWidth) > 235) {
                  this.$ekmtcCommon.showErrorTooltip(width, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
                if (Number(oogInfo.orgHeight) > 234) {
                  this.$ekmtcCommon.showErrorTooltip(height, 'Please check cargo size again! (In-gauge cargo)')
                  isOk = false
                }
              }
            }
          }
        }

        if (prefix === 'orgHeight' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }

        if (prefix === 'frgtWt') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            //this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else if (Number(selector.value) !== 0 && Number(selector.value) < 100) {
            //this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.023'))
            isOk = false
          }

          if (cntrTypCd === 'OT') {
            if (((Number(oogInfo.frgtWt) > 21980) && cntrSzCd === '20') ||
                ((Number(oogInfo.frgtWt) > 26500) && cntrSzCd === '40')
              ) {
              this.$ekmtcCommon.showErrorTooltip(selector, 'Regarding cargo\'s weight exceeds container\'s maximum capacity. Please proceed this cargo as Break Bulk.')
              isOk = false
            }
          } else if (cntrTypCd === 'FR') {
            let overCnt = 0

            if (cntrSzCd === '20') {
              if (Number(oogInfo.frgtWt) > 31260) {
                this.$ekmtcCommon.showErrorTooltip(selector, 'Regarding cargo\'s weight exceeds container\'s maximum capacity. Please proceed this cargo as Break Bulk.')
                isOk = false
              }
            } else if (cntrSzCd === '40') {
              if (Number(oogInfo.frgtWt) > 39400) {
                this.$ekmtcCommon.showErrorTooltip(selector, 'Regarding cargo\'s weight exceeds container\'s maximum capacity. Please proceed this cargo as Break Bulk.')
                isOk = false
              }
            }
          }
        }

        if (prefix === 'cmdtDsc') {
          if (this.$ekmtcCommon.isEmpty(oogInfo.cmdtDsc)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.389'))
            isOk = false
          }
        }

        return isOk
      },
      checkSize (flag, kind) {
        let szTyp = true
        if (!kind) {
          szTyp = this.changeSzTyp('Y')
        }

        if (!szTyp) {
          this.oogInputChk = true
          return
        }

        if (this.params.cntrTypCd !== 'XX') {
          const cntrInfo = this.params.cntrTypCd + this.params.cntrSzCd
          const overSizeInfo = this.overSizeInfo[cntrInfo]

          if (flag === 'orgLength') {
            const val = this.oogInfo.orgLength
            if (Number(val) > overSizeInfo.length) {
              this.overLength = Math.round((val - overSizeInfo.length) / 2)
            } else {
              this.overLength = 0
            }
          } else if (flag === 'orgWidth') {
            const val = this.oogInfo.orgWidth
            if (Number(val) > overSizeInfo.width) {
              this.overWidth = Math.round((val - overSizeInfo.width) / 2)
            } else {
              this.overWidth = 0
            }
          } else if (flag === 'orgHeight') {
            const val = this.oogInfo.orgHeight
            if (Number(val) > overSizeInfo.height) {
              this.overHeight = val - overSizeInfo.height
            } else {
              this.overHeight = 0
            }
          }

          if (this.params.cntrTypCd === 'FR') {
            if (Number(this.overLength) > 0 && Number(this.overWidth) === 0 && Number(this.overHeight) === 0) {
              this.simulation = 'ol'
              this.deadSpace = '2'
            } else if (Number(this.overLength) > 0 && Number(this.overWidth) > 0 && Number(this.overHeight) === 0) {
              this.simulation = 'owol'
              this.deadSpace = this.cntrSzCd === '20' ? '4' : '10'
            } else if (Number(this.overLength) === 0 && Number(this.overWidth) > 0 && Number(this.overHeight) === 0) {
              this.simulation = 'ow'
              this.deadSpace = this.cntrSzCd === '20' ? '2' : '4'
            } else if (Number(this.overLength) === 0 && Number(this.overWidth) > 0 && Number(this.overHeight) > 0) {
              this.simulation = 'ohow'
              this.deadSpace = this.cntrSzCd === '20' ? '5' : '10'
            } else if (Number(this.overLength) === 0 && Number(this.overWidth) === 0 && Number(this.overHeight) > 0) {
              this.simulation = 'oh'
              this.deadSpace = this.cntrSzCd === '20' ? '1' : '2'
            } else if (Number(this.overLength) > 0 && Number(this.overWidth) === 0 && Number(this.overHeight) > 0) {
              this.simulation = 'ohol'
              this.deadSpace = this.cntrSzCd === '20' ? '5' : '6'
            } else if (Number(this.overLength) > 0 && Number(this.overWidth) > 0 && Number(this.overHeight) > 0) {
              this.simulation = 'ohowol'
              this.deadSpace = this.cntrSzCd === '20' ? '9' : '22'
            } else {
              this.simulation = ''
              this.deadSpace = ''
            }
          } else if (this.params.cntrTypCd === 'OT') {
            if (Number(this.overHeight) > 0) {
              this.simulation = 'open'
              this.deadSpace = this.cntrSzCd === '20' ? '1' : '2'
            } else {
              this.simulation = ''
              this.deadSpace = ''
            }
          }
        }
        this.checkValidationOOG(flag)
      },
      checkValidationOOGAll () {
        const prefix = ['orgLength', 'orgWidth', 'orgHeight', 'frgtWt']
        let isOk = true
        prefix.forEach(el => {
          if (!this.checkValidationOOG(el)) {
            isOk = false
          }
        })

        return isOk
      },
      checkValidationAll () {
            const frm = document.querySelector('#frm')
            let isAllOk = true

            if (!this.checkValidationOOGAll()) {
              isAllOk = false
            }

            if (!this.changeSzTyp('Y')) {
              isAllOk = false
            }
            if (!this.checkPol()) {
              isAllOk = false
            }
            if (!this.checkPod()) {
              isAllOk = false
            }
            if (!this.checkCommodity()) {
              isAllOk = false
            }

            return isAllOk
        },
        isNotNumerCrmMsg (obj) {
          if (obj === 'frgtWt') { // Weight 기준 NUMBER(13,3)

          } else { // Weight 제외한 나머지 기준 = NUMBER(13,3)
              if (arr.length > 1 && arr[1].length > 2) {
              this.openAlert(this.$t('msg.SPEC020P020.011')) ////소수점이하 2자리까지만 가능합니다.
              event.target.value = ''
              event.target.focus()

              return
            }

            var length_snd = arr[0].length
            if (length_snd > 5) {
              this.openAlert(this.$t('msg.SPEC020P020.012')) ////정수는 5자리까지 입력가능합니다.
              event.target.value = ''
              event.target.focus()
            }
          }
        },
        closeFun () {
          this.$Simplert.close()
        },
        openAlert (msg) {
          let obj = {
            alertType: 'simple',
            onClose: this.closeFun,
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$i18n.t('msg.MYIN050T010.056'),
            useIcon: false,
            customClass: 'simple_alert',
            message: msg,
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
        },
        async insertOogRequest () {
          let isAllOk = this.checkValidationAll()

          if (isAllOk) {
          this.params.len = this.oogInfo.orgLength
          this.params.widt = this.oogInfo.orgWidth
          this.params.hght = this.oogInfo.orgHeight
          this.params.frgtWt = this.oogInfo.frgtWt

           console.log('insertOogRequest >>> ', this.params)
           await Oogs.oogInsert(this.params)
            .then(res => {
                this.openAlert(this.$t('msg.SPEC010G010.011'))

                if (res.data.reqNo !== '') {
                    // const detail = Oogs.getOogDetail(this.repReqNo)

                    // detail.then((data) => {
                      // const detailData = data.data

                      // this.inquiryDetail = detailData
                      this.repReqNo.push(res.data.reqNo)

                      this.detail = {
                        reqNos: this.repReqNo
                      }
                    // })
                } else {
                  this.openAlert(this.$t('js.common.004'))
                }
            })
          }
        },
        LocalShowErrorTooltip (elem, message, flag) {
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $input = ''
          let $msgBox = $parent.find('.tooltip_essen_box')

          if (flag === 'input') {
            $input = $elem.children('div').children('input').eq(0)
            $input.addClass('input_error')
          }
          $elem.addClass('input_error')
          $parent.addClass('position_relative')

          if ($msgBox.length === 0) {
            $msgBox = $('<p class=\'tooltip_essen_box\'><span class=\'tooltip_essen\'></span></p> ')
            $msgBox.appendTo($parent)
          }

          $msgBox.find('.tooltip_essen').eq(0).text(message).show()
        },
        localHideErrorTooltip (elem, flag) {
          const $elem = $(elem)
          const $parent = $elem.parents('td, div').eq(0)
          let $input = ''

          if (flag === 'input') {
            $input = $elem.children('div').children('input').eq(0)
            $elem.children('div').children('input').eq(0).removeClass('input_error')
          }

          $elem.removeClass('input_error')
          $parent.find('.tooltip_essen').hide()
      },
      async cargoCheck () {
          let isAllOk = true
          let szTyp = this.changeSzTyp('Y')
          if (!szTyp) {
            return
          }
          if (!this.checkValidationCghChkOOG()) {
              //isAllOk = false
          }

          if (isAllOk) {
          this.params.len = this.oogInfo.orgLength
          this.params.widt = this.oogInfo.orgWidth
          this.params.hght = this.oogInfo.orgHeight
          this.params.frgtWt = this.oogInfo.frgtWt
          const oogInfo = this.oogInfo
          const cntrTypCd = this.params.cntrTypCd
          const cntrSzCd = this.params.cntrSzCd

          let cgoCd = ''
          let isOk = false
          let rtn = 'O'

          if (cntrTypCd === 'FR') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 560) {
                //this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgLength'), this.$t('msg.CSBK100.199')) // 'Length(cm)은 560까지 입력 가능합니다.'
                isOk = false
                rtn += 'L'
              }
            } else if (Number(oogInfo.orgLength) > 1177) {
              //this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgLength'), this.$t('msg.CSBK100.200')) // 'Length(cm)은 1,177까지 입력 가능합니다.'
              isOk = false
              rtn += 'L'
            }
          }

          if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 588) {
               // this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgLength'), this.$t('msg.CSBK100.201')) // 'Length(cm)은 588까지 입력 가능합니다.'
                isOk = false
                rtn += 'L'
              }
            } else {
              if (Number(oogInfo.orgLength) > 1204) {
               // this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgLength'), this.$t('msg.CSBK100.202')) // 'Length(cm)은 1,204까지 입력 가능합니다.'
                isOk = false
                rtn += 'L'
              }
            }
          }

          if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgWidth) > 234) {
                //this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgWidth'), this.$t('msg.CSBK100.203'))
                isOk = false
                rtn += 'W'
              }
            } else {
              if (Number(oogInfo.orgWidth) > 235) {
               // this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgWidth'), this.$t('msg.CSBK100.204'))
                isOk = false
                rtn += 'W'
              }
            }
          }

          if (cntrTypCd === 'FR') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgWidth) > 243) {
                //this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgWidth'), this.$t('msg.CSBK100.203_1'))
                isOk = false
                rtn += 'W'
              }
            } else {
              if (Number(oogInfo.orgWidth) > 243) {
                //this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgWidth'), this.$t('msg.CSBK100.204_1'))
                isOk = false
                rtn += 'W'
              }
            }
          }

          if (cntrTypCd === 'HC') {
            if (cntrSzCd === '45') {
              if (Number(oogInfo.orgLength) > 1350) {
               // this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgLength'), this.$t('msg.CSBK100.201_1')) // 'Length(cm)은 588까지 입력 가능합니다.'
                isOk = false
                rtn += 'L'
              }
              if (Number(oogInfo.orgWidth) > 235) {
              //  this.$ekmtcCommon.showErrorTooltip(document.querySelector('#orgWidth'), this.$t('msg.CSBK100.204'))
                isOk = false
                rtn += 'W'
              }
            }
          }
          if (cntrTypCd === 'FR') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgHeight) > 221) {
                cgoCd = '01'
                rtn += 'H'
              } else {
                cgoCd = '02'
              }
            } else {
              if (Number(oogInfo.orgHeight) > 195) {
                cgoCd = '01'
                rtn += 'H'
              } else {
                cgoCd = '02'
              }
            }
          } else if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgHeight) > 231) {
                cgoCd = '01'
                rtn += 'H'
              } else {
                cgoCd = '02'
              }
            } else {
              if (Number(oogInfo.orgHeight) > 234) {
                cgoCd = '01'
                rtn += 'H'
              } else {
                cgoCd = '02'
              }
            }
          } else if (cntrTypCd === 'HC') {
            if (cntrSzCd === '45') {
              if (Number(oogInfo.orgHeight) > 269) {
                cgoCd = '01'
                rtn += 'H'
              } else {
                cgoCd = '02'
              }
            }
          }
          console.log('cgoCd>>>>>>>>> ', cgoCd)
          if (rtn !== 'O') {
            this.openAlert(rtn + ' (Out-of-gauge) Cargo')
          } else {
            this.openAlert('In-gauge cargo')
          }
        }
      },
      checkValidationCghChkOOG () {
        const prefix = ['orgLength', 'orgWidth', 'orgHeight']
        let isOk = true
        prefix.forEach(el => {
          if (!this.checkValidationOOG(el)) {
            isOk = false
          }
        })

        return isOk
      }
    }
}
</script>

<style scoped>
select {
  width: 150px
}
select option[value=""][disabled] {
 display: none;
}
.popup_wrap{
  -ms-overflow-style: none;
}
.popup_wrap::-webkit-scrollbar{
  display:none;
}

.txt_size_bk {
    color: black;
    text-align: left;
    margin-top: 5px;
    font-size: 12px;
}
</style>
