var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    directives: [
      {
        name: "model",
        rawName: "v-model",
        value: _vm.text,
        expression: "text",
      },
    ],
    class: _vm.inputClass,
    attrs: { type: "text", name: _vm.name, id: _vm.id, readonly: _vm.readonly },
    domProps: { value: _vm.text },
    on: {
      input: [
        function ($event) {
          if ($event.target.composing) return
          _vm.text = $event.target.value
        },
        function ($event) {
          return _vm.onInput($event)
        },
      ],
      keypress: function ($event) {
        return _vm.onKeypress($event)
      },
      keyup: function ($event) {
        return _vm.onKeyup($event)
      },
      blur: function ($event) {
        return _vm.onBlur($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }