var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "popup_wrap", staticStyle: { width: "800px" } },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c(
        "div",
        { staticClass: "popup_cont" },
        [
          _vm.repReqNo.length === 0
            ? _c("h1", { staticClass: "page_title" }, [
                _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.510")) + " "),
              ])
            : _vm._e(),
          _vm.repReqNo.length === 0
            ? _c("div", { staticClass: "content_box" }, [
                _c("form", { attrs: { id: "frm" } }, [
                  _c("table", { staticClass: "tbl_row" }, [
                    _vm._m(0),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v(_vm._s(_vm.$t("art.CMATK160")))]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.params.cntrSzCd,
                                  expression: "params.cntrSzCd",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { id: "cntrSzCd" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.params,
                                      "cntrSzCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.changeSzTyp("N")
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    selected: "",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.CSBL200.075")))]
                              ),
                              _vm._l(_vm.sizeList, function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.cd,
                                    domProps: { value: option.cd },
                                  },
                                  [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                        _c("th", [
                          _vm._v(" " + _vm._s(_vm.$t("art.CMATK161"))),
                        ]),
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.params.cntrTypCd,
                                  expression: "params.cntrTypCd",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { id: "cntrTypCd" },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.params,
                                      "cntrTypCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function ($event) {
                                    return _vm.changeSzTyp("N")
                                  },
                                ],
                              },
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    selected: "",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.CSBL200.075")))]
                              ),
                              _vm._l(_vm.typeList, function (option) {
                                return _c(
                                  "option",
                                  {
                                    key: option.cd,
                                    domProps: { value: option.cd },
                                  },
                                  [_vm._v(" " + _vm._s(option.cd) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _c("table", { staticClass: "tbl_col mt10" }, [
                    _vm._m(1),
                    _c("tbody", [
                      _c("tr", [
                        _c("th", [_vm._v("Length (cm)")]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "orgLength",
                                "is-comma": true,
                                point: 2,
                                "digit-max": 5,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkSize("orgLength")
                                },
                              },
                              model: {
                                value: _vm.oogInfo.orgLength,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oogInfo, "orgLength", $$v)
                                },
                                expression: "oogInfo.orgLength",
                              },
                            }),
                            _vm.overLength !== "" && _vm.overLength > 0
                              ? _c("div", { staticClass: "txt_size" }, [
                                  _vm._v(
                                    " Over Size: (F) +" +
                                      _vm._s(_vm.setComma(_vm.overLength)) +
                                      "㎝"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "(B) +" +
                                      _vm._s(_vm.setComma(_vm.overLength)) +
                                      "㎝ "
                                  ),
                                ])
                              : _vm.oogInfo.orgLength !== "" &&
                                _vm.overLength == 0
                              ? _c("div", { staticClass: "txt_size" }, [
                                  _vm._v(" In-gauge "),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("th", [_vm._v("Width (cm)")]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "orgWidth",
                                "is-comma": true,
                                point: 2,
                                "digit-max": 5,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkSize("orgWidth")
                                },
                              },
                              model: {
                                value: _vm.oogInfo.orgWidth,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oogInfo, "orgWidth", $$v)
                                },
                                expression: "oogInfo.orgWidth",
                              },
                            }),
                            _vm.overWidth !== "" && _vm.overWidth > 0
                              ? _c("div", { staticClass: "txt_size" }, [
                                  _vm._v(
                                    " Over Size: (L) +" +
                                      _vm._s(_vm.setComma(_vm.overWidth)) +
                                      "㎝"
                                  ),
                                  _c("br"),
                                  _vm._v(
                                    "(R) +" +
                                      _vm._s(_vm.setComma(_vm.overWidth)) +
                                      "㎝ "
                                  ),
                                ])
                              : _vm.oogInfo.orgWidth !== "" &&
                                _vm.overWidth == 0
                              ? _c("div", { staticClass: "txt_size" }, [
                                  _vm._v(" In-gauge "),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _c("tr", [
                        _c("th", [_vm._v("Height (cm)")]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "orgHeight",
                                "is-comma": true,
                                point: 2,
                                "digit-max": 5,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.checkSize("orgHeight")
                                },
                              },
                              model: {
                                value: _vm.oogInfo.orgHeight,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oogInfo, "orgHeight", $$v)
                                },
                                expression: "oogInfo.orgHeight",
                              },
                            }),
                            _vm.overHeight !== "" && _vm.overHeight > 0
                              ? _c("div", { staticClass: "txt_size" }, [
                                  _vm._v(
                                    " Over Size: +" +
                                      _vm._s(_vm.setComma(_vm.overHeight)) +
                                      "㎝ "
                                  ),
                                ])
                              : _vm.oogInfo.orgHeight !== "" &&
                                _vm.overHeight == 0
                              ? _c("div", { staticClass: "txt_size" }, [
                                  _vm._v(" In-gauge "),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("th", [_vm._v("Weight (kg)")]),
                        _c(
                          "td",
                          [
                            _c("e-input-number", {
                              attrs: {
                                id: "frgtWt",
                                "is-comma": true,
                                point: 3,
                                "digit-max": 7,
                              },
                              model: {
                                value: _vm.oogInfo.frgtWt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.oogInfo, "frgtWt", $$v)
                                },
                                expression: "oogInfo.frgtWt",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "txt_size_bk" }, [
                  _vm._v(" * cm 기준입니다. "),
                ]),
              ])
            : _vm._e(),
          _vm.repReqNo.length > 0
            ? _c("OogDetailPop", {
                staticStyle: { width: "700" },
                attrs: { detail: _vm.detail },
              })
            : _vm._e(),
          _vm.repReqNo.length === 0
            ? _c("div", { staticClass: "mt10 text_center" }, [
                _c(
                  "a",
                  {
                    staticClass: "button blue lg",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cargoCheck()
                      },
                    },
                  },
                  [_vm._v("조회")]
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "130" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "25%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }