<template>
  <e-auto-complete class="auto-input"
                   v-model="text"
                   :values="dynamicItems"
                   :read-only="readOnly"
                   :disabled="disabled"
                   :is-char="isChar"
                   :is-filter="false"
                   :is-char-alert="isCharAlert"
                   @update="dynamicSearchPlace"
                   @input="inputEvt"
                   :focus-force="focusForce"
  >
  </e-auto-complete>
</template>

<script>
import apiCommons from '@/api/services/commons'
import EAutoComplete from '@/components/common/EAutoCompleteWithCode'

export default {
  name: 'EAutoCompleteCommondity',
  components: {
    EAutoComplete
  },
  props: {
    ctrCd: { type: String, default: '', required: false },
    cdNm: { type: String, default: '', required: false },
    readOnly: { type: Boolean, default: false, required: false },
    disabled: { type: Boolean, default: false, required: false },
    isValue: { type: Boolean, default: true, required: false },
    isChar: { type: Boolean, default: true, required: false },
    isCharAlert: { type: Boolean, default: true, required: false },
    focusForce: { type: Boolean, default: false, required: false }
  },
  data: function () {
    return {
      text: '',
      rtnVo: {},
      items: [],
      dynamicItems: [],
      preKeyowrd: '',
      intervalFunc: undefined
    }
  },
  watch: {
    cdNm () {
      if (this.text !== this.$props.cdNm) {
        this.text = this.$props.cdNm
      }
    },
    isValue () {
      if (!this.$props.isValue) {
        this.rtnVo = { cd: '', cdNm: '' }
        this.$emit('change', this.rtnVo)
      }
    }
  },
  created () {
    if (this.text !== this.$props.cdNm) {
      this.text = this.$props.cdNm
    }
  },
  methods: {
    dynamicSearchPlace: function (e) {
      const THIS = this
      const keyword = e

      if (keyword === '' || keyword.length < 1) {
        this.items = []
        this.dynamicItems = []
        return
      } else if (keyword.length === 1) {
        this.items = []
        this.dynamicItems = [{
          cd: '',
          cdNm: keyword.toUpperCase(),
          displayText: `${THIS.$t('msg.CSBK100.050')} <span style='color:#d8d8d8;'>(${keyword.toUpperCase()})</span>`
        }]
        return
      }

      // backspace, del key로 단어 삭제시
      if (keyword !== this.preKeyowrd && this.preKeyowrd.indexOf(keyword) === 0) {
        this.preKeyowrd = ''
      // 붙여 넣기 등으로 글자 자체가 변경 되었을때
      } else if (this.preKeyowrd.indexOf(keyword) === -1 && this.preKeyowrd !== keyword) {
        this.preKeyowrd = ''
      }

      // console.log('keyword => ', keyword)

      if (this.intervalFunc !== undefined) {
        clearInterval(this.intervalFunc)
        this.intervalFunc = undefined
      }
      this.intervalFunc = setInterval(function () {
        apiCommons.getCommonsCodesCommodity(keyword, THIS.ctrCd).then((data) => {
          if (data.headers.respcode === 'C0000') {
            THIS.items = data.data
            if (THIS.items === undefined) {
              THIS.dynamicItems = []
            } else {
              THIS.dynamicItems = THIS.items.map(vo => {
                return {
                  cd: vo.cd,
                  cdNm: vo.cdNm,
                  displayText: vo.cdNm
                }
              })
            }

            THIS.dynamicItems.push({
              cd: '',
              cdNm: keyword.toUpperCase(),
              displayText: `${THIS.$t('msg.CSBK100.050')} <span style='color:#d8d8d8;'>(${keyword.toUpperCase()})</span>`
            })
          }
        })
        clearInterval(THIS.intervalFunc)
        THIS.intervalFunc = undefined
      }, 300)
    },
    inputEvt (vo) {
      const rtn = {
        cd: vo.cd,
        cdNm: vo.cdNm,
        isSelected: true
      }
      this.$emit('change', rtn)
    }
  }
}
</script>
