<template>
  <input type="text"
         v-model="text"
         :name="name"
         :id="id"
         :readonly="readonly"
         :class="inputClass"
         @input="onInput($event)"
         @keypress="onKeypress($event)"
         @keyup="onKeyup($event)"
         @blur="onBlur($event)"
  />
</template>

<script>
export default {
  name: 'EInputNumber',
  props: {
    name: { type: String, default: '', required: false },
    id: { type: String, default: '', required: false },
    readonly: { type: Boolean, default: false, required: false },
    inputClass: { type: String, default: '', required: false },
    value: { type: [String, Number], default: '', required: false },
    isComma: { type: Boolean, default: false, required: false }, // 콤마 여부
    point: { type: Number, default: 0, required: false }, // 허용가능 소수점 자리수
    digitMax: { type: Number, default: 0, required: false }, // 정수 허용 숫자 (0 일경우 제한 없음)
    isPhone: { type: Boolean, default: false, required: false }, // 정수가 0부터 시작가능여부
    isMinus: { type: Boolean, default: false, required: false },
    isReturnNumber: { type: Boolean, default: false, required: false },
    isCPhone: { type: Boolean, default: false, required: false } // 숫자 + *
  },
  data () {
    return {
      elemId: '',
      text: '',
      rtnText: '',
      rtnNumber: 0
    }
  },
  watch: {
    value () {
      if (this.rtnText !== '' + this.$props.value) {
        const rtn = this.numberWithCommas('' + this.$props.value)
        this.text = rtn.str
        this.rtnText = rtn.num
      }
    }
  },
  created () {
    const rtn = this.numberWithCommas('' + this.$props.value)
    this.text = rtn.str
    this.rtnText = rtn.num
  },
  methods: {
    onInput (e) {
      const rtn = this.numberWithCommas(e.target.value)
      e.target.value = rtn.str

      this.text = rtn.str
      this.rtnText = rtn.num
      this.rtnNumber = rtn.num === '' ? 0 : Number(rtn.num)

      if (this.rtnText !== '' && this.$props.isReturnNumber) {
        this.$emit('input', this.rtnNumber)
      } else {
        this.$emit('input', this.rtnText)
      }
    },
    onBlur (e) {
      const rtn = this.numberWithCommas(e.target.value)
      e.target.value = rtn.str

      this.text = rtn.str
      this.rtnText = rtn.num
      this.rtnNumber = rtn.num === '' ? 0 : Number(rtn.num)

      if (this.rtnText !== '' && this.$props.isReturnNumber) {
        this.$emit('blur', this.rtnNumber)
      } else {
        this.$emit('blur', this.rtnText)
      }
    },
    onKeypress (e) {
      const keyCode = e.keyCode

      if (this.isCPhone && keyCode === 42) {
        return
      }
      // - (마이너스)
      if (this.isMinus && keyCode === 45) {
        return
      }
      // .
      if (this.point !== 0 && keyCode === 46) {
        return
      }
      // 숫자
      if (keyCode >= 48 && keyCode <= 57) {
        return
      }

      e.preventDefault()
    },
    onKeyup (e) {
      // const rtn = this.numberWithCommas(this.text)
      // this.text = rtn.str
      // this.rtnText = rtn.num

      this.$emit('keyup', e)
    },
    numberWithCommas (val) {
      const rtn = {
        str: '',
        num: ''
      }

      if (val === '') {
        return rtn
      }

      if (val === '0') {
        rtn.str = '0'
        rtn.num = '0'
        return rtn
      }

      const len = val.length
      const arrNum = []
      const arrPoint = []
      let hasMinus = false // 마이너스 여부
      let hasPoint = false // 소수점 여부
      let hasAsterisk = false

      for (let i = 0; i < len; i++) {
        if (val.charAt(i) === '-') {
          if (this.isMinus && i === 0) {
            hasMinus = true
          }
          continue
        } else if (val.charAt(i) === '.') {
          if (!hasPoint) {
            hasPoint = true
          }
          continue
        } else if (val.charAt(i) === '*') {
          if (this.isCPhone) {
            hasAsterisk = true
          }
        }

        if (val.charAt(i) === '*') {
          if (hasAsterisk) {
            arrNum.push(val.charAt(i))
          }
        }

        if (val.charAt(i) >= '0' && val.charAt(i) <= '9') {
          if (!hasPoint) {
            arrNum.push(val.charAt(i))
          } else {
            arrPoint.push(val.charAt(i))
          }
        }
      }

      let num = arrNum.join('')
      let point = arrPoint.join('')
      let isPoint = this.point !== 0 && hasPoint

      if (!this.isPhone && num !== '' && num !== '0') {
        while (num !== '0' && num.indexOf('0') === 0) {
          num = num.substring(1)
        }
      }

      if (this.digitMax > 0 && num.length > this.digitMax) {
        num = num.substring(0, this.digitMax)
      }

      if (this.point > 0 && point.length > this.point) {
        point = point.substr(0, this.point)
      }

      if (isPoint && num === '') {
        num = '0'
      }

      if (this.isComma) {
        let reg = /(^[+-]?\d+)(\d{3})/
        let ret = (num + '')

        while (reg.test(ret)) {
          ret = ret.replace(reg, '$1' + ',' + '$2')
        }

        rtn.str = (hasMinus ? '-' : '') + ret + (isPoint ? '.' + point : '')
        rtn.num = (hasMinus ? '-' : '') + num + (isPoint ? '.' + point : '')
      } else {
        rtn.str = (hasMinus ? '-' : '') + num + (isPoint ? '.' + point : '')
        rtn.num = (hasMinus ? '-' : '') + num + (isPoint ? '.' + point : '')
      }

      return rtn
    }
  }
}
</script>
